/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  /* SLIDER PRODUCER */
  setTimeout(slider_producer, 300);

  function slider_producer() {
    var slider_width = $('.producer-slider .item-wrapper').outerWidth();
    var item_number = $('.producer-slider .move .item').length;
    var click_number = 0;

    if (window.matchMedia("(max-width: 425px)").matches) {
      $('.producer-slider .item').css('width', slider_width/1);
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      $('.producer-slider .item').css('width', slider_width/3);
    } else {
      $('.producer-slider .item').css('width', slider_width/4);
    }

    $('.producer-slider .nav-prev').prop('disabled', true);

    $('.producer-slider .nav-prev').on('click', function () {
      $('.producer-slider .nav-next').prop('disabled', false);
      click_number = click_number - 1;
      if(click_number > 0) {
        var left_position = parseInt($('.producer-slider .move').css('left'));

        if (window.matchMedia("(max-width: 425px)").matches) {
          $('.producer-slider .move').css('left', left_position + slider_width/1);
        } else if (window.matchMedia("(max-width: 768px)").matches) {
          $('.producer-slider .move').css('left', left_position + slider_width/3);
        } else {
          $('.producer-slider .move').css('left', left_position + slider_width/4);
        }

      } else {
        $('.producer-slider .nav-prev').prop('disabled', true);
      }
    });

    $('.producer-slider .nav-next').on('click', function () {
      $('.producer-slider .nav-prev').prop('disabled', false);
      click_number = click_number + 1;

      if (window.matchMedia("(max-width: 425px)").matches) {
        if((click_number) < item_number) {
          var left_position = parseInt($('.producer-slider .move').css('left'));

          $('.producer-slider .move').css('left', left_position - slider_width/1);
        } else {
          $('.producer-slider .nav-next').prop('disabled', true);
        }
      } else if (window.matchMedia("(max-width: 768px)").matches) {
        if((click_number + 3) <= item_number) {
          var left_position = parseInt($('.producer-slider .move').css('left'));

          $('.producer-slider .move').css('left', left_position - slider_width/3);
        } else {
          $('.producer-slider .nav-next').prop('disabled', true);
        }
      } else {
        if((click_number + 4) <= item_number) {
          var left_position = parseInt($('.producer-slider .move').css('left'));

          $('.producer-slider .move').css('left', left_position - slider_width/4);
        } else {
          $('.producer-slider .nav-next').prop('disabled', true);
        }
      }

    });
  };

  /* Galerie */
  $("a[rel^='prettyPhoto']").prettyPhoto({
    social_tools: '',
  });

})(jQuery); // Fully reference jQuery after this point.
